import React from 'react';
import VOMaker from './VOMaker';

function App() {
  return (
    <div className="App">
      <VOMaker />
    </div>
  );
}

export default App;
