import React, { useState, useRef } from 'react';
import styles from './VOMaker.module.css';

const VOMaker = () => {
  const [voice, setVoice] = useState('alloy');
  const [myvotext, setMyvotext] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [audioSrc, setAudioSrc] = useState('');
  const textareaRef = useRef(null);
  const audioRef = useRef(null);

  const bear = process.env.REACT_APP_API_KEY; // Use the environment variable

  const fetchAndPlaySpeech = async (text) => {
    setIsFetching(true);
    try {
      console.log('Making API call with text:', text);
      const response = await fetch('https://api.openai.com/v1/audio/speech', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${bear}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: "tts-1-hd",
          input: text,
          voice: voice,
        })
      });

      if (!response.ok) throw new Error('Network response was not ok');

      const blob = await response.blob();
      const audioUrl = URL.createObjectURL(blob);
      setAudioSrc(audioUrl);
    } catch (error) {
      console.error('Error fetching audio:', error);
    } finally {
      setIsFetching(false);
    }
  };

  const handleCreateClick = () => {
    const text = textareaRef.current.value.trim();
    if (text === '') {
      alert('Please enter some text');
      return;
    }
    setMyvotext(text);
    fetchAndPlaySpeech(text);
  };

  return (
    <div className={styles.container}>
      <div className={styles.inputArea}>
        <textarea
          ref={textareaRef}
          className={styles.textarea}
          placeholder="Enter your text here"
        ></textarea>
        <select
          className={styles.dropdown}
          value={voice}
          onChange={(e) => setVoice(e.target.value)}
        >
          <option value="alloy">Alloy</option>
          <option value="echo">Echo</option>
          <option value="fable">Fable</option>
          <option value="onyx">Onyx</option>
          <option value="nova">Nova</option>
          <option value="shimmer">Shimmer</option>
        </select>
        <div className={styles.audioBar}>
          <audio ref={audioRef} controls className={styles.audioControl} src={audioSrc}></audio>
        </div>
        <button
          className={styles.createButton}
          onClick={handleCreateClick}
          disabled={isFetching}
        >
          {isFetching ? 'Loading...' : 'Create'}
        </button>
      </div>
    </div>
  );
};

export default VOMaker;
